export default function authHeaderFile() {
  let dataUser = JSON.parse(localStorage.getItem('user'));
  if (dataUser && dataUser.access_token) {
      return { 
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        "Authorization": dataUser.token_type+' ' + dataUser.access_token };
    } else {
      return {};
    }
  }